<template>
  <div class="NotFound p-4" :style="'background-image: url(' + random_background + ');'">
    <div class="text-h3 pt-4 title">Page not found.</div>
    <div class="mt-4">
        The page you have requested could not be found on this server.
    </div>
    <div class="paragraph-text mt-4">
        Try going back to <a href="/">home page</a> and repeating your action. Or,
        contact us for support.
    </div>
  </div>
</template>

<script>
export default {
    name: 'NotFound',
    computed: {
        random_background: function () {
            var array = ['/img/backgrounds/404_01.jpg', '/img/backgrounds/404_02.jpg', '/img/backgrounds/404_03.jpg']
            var random = this.$shuffle(array)

            return random[0]
        },
    },
}
</script>

<style scoped lang="scss">
    .paragraph-text {
        font-size: 18px;
    }
    .NotFound {
        width: 100%; 
        height: 100vh; 
        display: flex;
        flex-direction: column;
        flex-grow: 1; 
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        justify-content: flex-end;
        text-shadow: 0 2px 5px rgba(0, 0, 0, .5);
    }
</style>